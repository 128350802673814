import React, { useEffect } from 'react'
import { Avatar, IconButton, Item, Token, Icon, IconName } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import {
  useGetContractsSettings,
  useGetEmployeeSettings,
  useGetOrganisationSettings,
  useGetScreeningSettings,
} from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { pathToUrl } from '@src/utils/router'
import { AnonymizedWarning } from '../../Preview/components/AnonymizedWarning'
import { InactivityWarning } from '../../Preview/components/Buttons/ChangeStatusDropdown/SetInactive/InactivityWarning'
import { PendingChangesWarning } from '../../Preview/components/PendingChangesWarning'
import { TerminationDateRequiredWarning } from '../../Preview/components/TerminationDateRequiredWarning'
import {
  canTerminateEmployees,
  canViewChangelog,
  canViewContracts,
  canViewFindings,
  canViewKeyPerson,
  canViewOwnership,
  canViewPersonal,
  canViewRightToWork,
  canViewRisk,
  canViewScreening,
  canViewTermination,
} from '../../Preview/ProfileSummary/common'
import { OnboardingWarning } from '../../Preview/ProfileSummary/components/OnboardingWarning'
import { TwoColumnsLayout } from '../common/TwoColumnsLayout'
import { LayoutTabProps } from '../common/types'
import { Badges } from './Badges'
import { Contracts } from './Contracts'
import { PersonalDetails } from './PersonalDetails'
import { Reports } from './Reports'
import { RightToWork } from './RightToWork'
import { TerminationWidget } from './Termination'
import { WorkDetails } from './WorkDetails'
import { useParams, useLocation } from 'react-router-dom'
import { useScrollToTop } from '@src/hooks/useScrollToTop'

type NavigationItemProps = {
  title: string
  path: string
  icon: IconName
  params: Object
  description?: string
}
const NavigationItem = ({
  title,
  path,
  icon,
  params,
  description,
}: NavigationItemProps) => (
  <Item use={InternalLink} to={pathToUrl(path, params)}>
    <Item.Avatar>
      <Avatar useIcon={<Icon name={icon} />} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{description}</Item.Description>
    </Item.Content>
    <Item.Side>
      <IconButton useIcon={<Icon name="ChevronRight" />} color={Token.color.greyTone20} />
    </Item.Side>
  </Item>
)

export const SummaryLayoutTab = ({
  data,
  primaryDataHandler,
  dynamicGroups,
  sectionsData,
  statusTransitions,
  employeeInactivity,
}: LayoutTabProps) => {
  const params = useParams()
  const { data: employeeSettings } = useGetEmployeeSettings()
  const { data: contractsSettings } = useGetContractsSettings()
  const { data: screeningSettings } = useGetScreeningSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()

  const isCommercial = useIsCommercial()

  const showOnboardingWarning = ['onboarding', 'hired', 'not_hired', 'pending'].includes(
    data.status.id,
  )
  const showTerminationDateRequiredWarning =
    canTerminateEmployees(data) &&
    data.employee_type === 'external' &&
    !data.has_termination &&
    contractsSettings?.force_termination_enabled

  const showTermination = canViewTermination(data) && data.has_termination

  const location = useLocation()
  const { scrollToTop } = useScrollToTop()

  useEffect(() => {
    scrollToTop()
  }, [location])

  return (
    <>
      <TwoColumnsLayout
        left={
          <>
            <AnonymizedWarning employee={data} />
            <PendingChangesWarning employee={data} />
            <InactivityWarning
              employee={data}
              employeeInactivity={employeeInactivity}
              statusTransitions={statusTransitions}
            />
            {showTerminationDateRequiredWarning && (
              <TerminationDateRequiredWarning data={data} />
            )}
            {showOnboardingWarning && <OnboardingWarning data={data} />}
            {showTermination && <TerminationWidget data={data} />}
            <WorkDetails
              data={data}
              primaryDataHandler={primaryDataHandler}
              dynamicGroups={dynamicGroups}
              sectionsData={sectionsData}
            />
            {canViewContracts(data) && <Contracts data={data} />}
            {canViewPersonal(data) && (
              <PersonalDetails data={data} dynamicGroups={dynamicGroups} />
            )}
            {canViewChangelog(data) && (
              <NavigationItem
                description="Main changes and events"
                icon="16/Timeline"
                title="Timeline"
                params={{ id: String(data.id) }}
                path={ROUTES.FORMS.EMPLOYEE.TIMELINE}
              />
            )}
            {canViewScreening(data, !!screeningSettings?.enabled) && (
              <NavigationItem
                title="Screening"
                path={ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING}
                icon="Search"
                params={params}
              />
            )}
            {canViewRisk(data, !!organisationSettings?.risk?.enabled) && (
              <NavigationItem
                title="Risk"
                path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.SUMMARY}
                icon="16/Warning"
                params={params}
              />
            )}
          </>
        }
        right={
          <>
            {employeeSettings?.enable_dynamic_groups_badges && <Badges data={data} />}
            <Reports data={data} />
            {canViewRightToWork(data) && <RightToWork data={data} />}
            {canViewKeyPerson(data, !isCommercial) && (
              <NavigationItem
                title="Key persons"
                path={ROUTES.FORMS.EMPLOYEE.KEY_PERSON}
                icon="Gift"
                params={params}
              />
            )}
            {canViewOwnership(data) && (
              <NavigationItem
                title="Ownership"
                path={ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL}
                icon="Profile"
                params={params}
              />
            )}
            {canViewFindings(data, !!organisationSettings?.findings?.enabled) && (
              <NavigationItem
                title="Findings"
                path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.FINDINGS}
                icon="16/ListNumber"
                params={params}
              />
            )}
          </>
        }
      />
    </>
  )
}
